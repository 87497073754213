import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 2-2-2-2-2\\@90%1RM`}</p>
    <p>{`Strict Pullups (weighted if possible) 2-2-2-2-2`}</p>
    <p>{`then ,`}</p>
    <p>{`10 Rounds for time of:`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`10-Pushups (yes, hand release)`}</p>
    <p>{`10-Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Starting next week we will no longer have a 6:30pm class on Tuesday
& Thursday at East due to low attendance.  `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      